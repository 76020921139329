import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ThemeContext } from "../context/ThemeContext"
import Typewriter from "typewriter-effect"

import "../assets/styles/pages/_index.scss"
import { Link } from "gatsby"

const IndexPage = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("white")
    theme.setOriginPage({ label: "Home", to: "/" })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ACME Produzioni | App, software per hospitality e organizzazione eventi</title>
        <meta name="description" content="ACME nasce nel mondo dell’intrattenimento;
        nel tempo ci siamo occupati di diverse attività come lo sviluppo
        di software e APP puntando sempre all’eccellenza." />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="home">
        <div className="columns is-gapless">
          <div className="column grey">
            <Link to="/tech-soft">
              <h1>Tech &amp; Software</h1>
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .typeString(
                      "<p>Hotel</p> <p>Parrucchieri</p> <p>Centri estetici</p> <p>SPA</p> <p>Ristorazione</p> <p>Siti web</p> <p>APP &amp;<span/> Software Personalizzati</p> <p>Reti WiFi</p> <p>Infrastrutture di rete</p>"
                    )
                    .start()
                }}
                options={{
                  delay: 50,
                }}
              />
            </Link>
          </div>
          <div className="column red">
            <Link to="/entertainment">
              <h1>Entertainment</h1>
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .typeString(
                      "<p>Organizzazione</p> <p>Concerti</p> <p>Spettacoli Live</p> <p>Teatro</p> <p>Mostre</p> <p>Convegni</p> <p>Noleggio materiale audio presa diretta</p> <p>Cinema</p> <p></p> <p></p>"
                    )
                    .start()
                }}
                options={{
                  delay: 50,
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
